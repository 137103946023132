import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const FeaturesTable = styled.table`
  margin-bottom: 9rem;
  margin-top: 1rem;
  table-layout: fixed;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 769px) {
    display: none;
  }
  & thead tr th {
    padding: 24px 15px 40px;
    &:not(:first-child) {
      border-left: 1px solid #ebebeb;
    }
    & div {
      & p {
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 4px;
      }
      & span {
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  & thead th {
    border: none;
    &.plan {
      text-align: center;
      vertical-align: middle;
    }
  }

  & tbody tr th {
    padding: 16px 0 16px 16px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.title {
      display: table-cell;
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;
      padding-top: 64px;
      font-weight: 900;
      text-transform: uppercase;
    }

    span {
      display: inline-block;
      position: relative;
    }
    i {
      transform: scale(0.7);
      margin-left: 4px;
      border: 1px solid;
      border-radius: 50%;
      /* display: flex; */
      min-width: 20px;
      height: 20px;
      display: inline-block;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      max-width: 18px;
      color: #aaa;

      svg {
        fill: #aaa;
      }

      &:hover {
        color: black;
        svg {
          fill: black;
        }
      }
    }
  }
  & th,
  td {
    padding: 0 16px;
    text-align: center;
    vertical-align: middle !important;
    border: none;
    font-size: 14px;
    font-weight: 400;
    &:last-child {
      border-radius: 4px;
    }
  }
  & td {
    border-left: 1px solid #ebebeb;
  }
  .subinfo {
    color: #788487;
    font-weight: 300;
    font-size: 0.75rem;
    max-width: 265px;
  }

  & tr {
    th {
      &:first-child {
        text-align: left;
      }
    }
  }

  @media (max-width: 810px) {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    text-align: center;
    & td {
      padding: 0;
    }
    & th {
      padding-left: 0;
    }
  }

  & thead {
    background-color: #fff;
  }

  & tr {
    background-color: #fff;
    border-radius: 4px;
  }

  & tbody tr:nth-child(even) {
    background-color: #f8f8f8;
    border-radius: 4px;
  }

  & img {
    max-width: 12px;
  }
  & .icon-cross {
    color: #f50057;
    font-size: 25px;
  }
`;

export const Header = styled.div`
  margin: 54px 0 76px;
  display: flex;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
  & a {
    margin-top: 32px;
  }
  p {
    margin: 24px 0 0 0;
  }
  ${media.mobile} {
    margin: 0 0 0;
  }
`;
