import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import Button from 'components/ui/button';
import Tippy from '@tippyjs/react';
import checkmark from 'img/ui/checkmarkBlue.svg';
import { Range, getTrackBackground } from 'react-range';
import { getStepForValue, getPriceForValue, getSavings, data as pricingData } from 'data/pricing';
import * as Styled from './styled';
import { Plans } from '../../../data/pricing/plans';

const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const PricingCreator = ({
  type,
  currency,
  planName,
  features,
  mostPopular = false,
  use,
  data,
  custom,
  free,
  style,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [range, setRange] = useState([data?.range?.default]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth > 576) {
      setOpen(true);
    }
  }, []);

  const handleRangeChange = (value) => setRange(value);

  const list = features.map((f) => (
    <p key={f.name}>
      <img src={checkmark} alt="checkmark" />
      {f.tooltip ? (
        <Tippy content={f.tooltip} className="custom">
          <Styled.TextFeature>{f.name}</Styled.TextFeature>
        </Tippy>
      ) : (
        <Styled.TextFeature
          style={{
            borderBottom: 'none',
          }}
        >
          {f.name}
        </Styled.TextFeature>
      )}
    </p>
  ));

  let savings = 0;
  if (data?.prices) savings = getSavings(range / 1000, data.prices, currency, type);

  return (
    <Styled.Box
      free={free}
      mostPopular={mostPopular}
      style={{
        ...style,
      }}
      className={[
        custom ? 'custom' : '',
        free ? 'free' : '',
        mostPopular ? 'most-popular' : '',
        'pricing-box',
      ].join(' ')}
    >
      {/* {mostPopular && <Styled.Badge>MOST POPULAR</Styled.Badge>} */}
      <Styled.Inner>
        <Styled.PricingHeader>
          <Styled.PlanName>{planName}</Styled.PlanName>
          <Styled.Use>{use}</Styled.Use>
          <Styled.PriceBox>
            <Styled.Price startsAt={data?.range}>
              <div style={{ display: 'flex' }}>
                <Styled.Currency>{currency === 'usd' ? '$ ' : '€ '}</Styled.Currency>
                {data?.prices ? getPriceForValue(range / 1000, data.prices, currency, type) : 0}
                {!!savings && <Styled.Saving>(save {savings}%)</Styled.Saving>}
              </div>
              <Styled.PageviewsCTA>
                {free && (
                  <span>
                    includes{' '}
                    <strong>{numberWithSpaces(pricingData[Plans.Freemium].range.max)}</strong> free
                    sessions
                  </span>
                )}
                {!free && (
                  <span>
                    {type === 'annual' ? 'monthly' : 'monthly'} for{' '}
                    <strong>{numberWithSpaces(range) || '1 000'}</strong> sessions
                  </span>
                )}
              </Styled.PageviewsCTA>
            </Styled.Price>
            {custom && (
              <Styled.CustomPricing>
                <p className="custom-pricing">Custom</p>
                <p className="starts-at">
                  starts from{' '}
                  <strong>{numberWithSpaces(pricingData[Plans.Enterprise].range.min)}</strong>{' '}
                  sessions
                </p>
              </Styled.CustomPricing>
            )}
            {data?.range && (
              <Styled.Range>
                <Range
                  min={data?.range?.min}
                  max={data?.range?.max}
                  step={getStepForValue(range)}
                  values={range}
                  onChange={handleRangeChange}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: '4px',
                        width: '100%',
                        background: getTrackBackground({
                          values: range,
                          min: data?.range?.min,
                          max: data?.range?.max,
                          colors: ['#0446CA', '#EBEBEB'],
                        }),
                        margin: '20px 0 12px',
                        borderRadius: '20px',
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: '16px',
                        width: '16px',
                        backgroundColor: '#0446CA',
                        borderRadius: '50%',
                        outline: 0,
                      }}
                    />
                  )}
                />
                <div>
                  <p>{data?.range?.labels[0]}</p>
                  <p>{data?.range?.labels[1]}</p>
                </div>
              </Styled.Range>
            )}
          </Styled.PriceBox>

          <Button sourceID={`pricing-${planName}`} signUp secondary reversed={free} small>
            {custom ? 'Contact us' : free ? 'Get started' : 'Sign up free'}
          </Button>
        </Styled.PricingHeader>

        <Styled.SeeFeatures onClick={() => setOpen(!isOpen)}>
          See features <i className={isOpen ? 'icon-chevron-up' : 'icon-chevron-down'} />
        </Styled.SeeFeatures>
        <Collapse isOpen={isOpen} style={{ width: '100%' }}>
          <Styled.Features>{list}</Styled.Features>
        </Collapse>
      </Styled.Inner>
    </Styled.Box>
  );
};

export default PricingCreator;

PricingCreator.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.shape({
    prices: PropTypes.arrayOf(PropTypes.object).isRequired,
    range: PropTypes.shape({
      min: PropTypes.string.isRequired,
      max: PropTypes.string.isRequired,
      step: PropTypes.string.isRequired,
      default: PropTypes.number.isRequired,
      labels: PropTypes.arrayOf([PropTypes.string]).isRequired,
    }),
    usd: PropTypes.shape({
      multiplier: PropTypes.shape({
        annual: PropTypes.number.isRequired,
        monthly: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    eur: PropTypes.shape({
      multiplier: PropTypes.shape({
        annual: PropTypes.number.isRequired,
        monthly: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  planName: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  mostPopular: PropTypes.bool,
  free: PropTypes.bool,
  custom: PropTypes.bool,
  use: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
};

PricingCreator.defaultProps = {
  mostPopular: false,
  custom: false,
  free: false,
  style: undefined,
};
