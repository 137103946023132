import React from 'react';
// import ContactWithUs from 'components/ui/contactwithus';
import CustomLink from 'components/ui/link';
import * as Styled from './styled';

const Enterprise = () => {
  // const features = [
  //   'Custom number of sessions',
  //   'Custom invoicing',
  //   'Priority support',
  //   'Dedicated onboarding & implementation',
  //   'Custom features & integration',
  // ];

  return (
    <>
      {/* <Styled.Box>
        <Styled.InfoBox>
          <div className="left">
            <div className="description">
              <h2>Enterprise</h2>
              <Styled.Description>
                Need something more? We can create a solution tailored to your business needs.
              </Styled.Description>
            </div>
            <ContactWithUs pricing mail="sales@livesession.io" />
          </div>
          <div className="right">
            <h3>All premium features and more:</h3>
            <ul>
              {features.map((item) => (
                <li key={item}>
                  <i className="icon-dot-single" />
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </Styled.InfoBox>
      </Styled.Box> */}
      <Styled.ExtendGuestPostBox className="container">
        <a href="/early-stage-program/" className="box">
          <h3>Early-stage startup program</h3>
          <p>Save money with our early-stage program.</p>
          <CustomLink tag="p" withArrow>
            Join to the program
          </CustomLink>
        </a>
        <a href="/badge/" className="box">
          <h3>+5,000 sessions for free</h3>
          <p>Add our badge to your website and get extra sessions.</p>
          <CustomLink tag="p" withArrow>
            Apply for sessions
          </CustomLink>
        </a>
      </Styled.ExtendGuestPostBox>
    </>
  );
};

export default Enterprise;
