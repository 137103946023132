import { Pricing } from './pricing_gen';

export const Plans = {
  Freemium: Pricing.plans_all.freemium,
  Basic: Pricing.plans_all.basic,
  Professional: Pricing.plans_all.professional,
  Enterprise: Pricing.plans_all.enterprise,
};

export const showPlans = [Plans.Freemium, Plans.Basic, Plans.Professional, Plans.Enterprise];

export function planPriority(plan) {
  switch (plan) {
    case Plans.Freemium:
      return 1;
    case Plans.Basic:
      return 2;
    case Plans.Professional:
      return 3;
    case Plans.Enterprise:
      return 4;
    default:
      return 0;
  }
}
