import { Pricing } from './pricing_gen';

function kFormatter(num) {
  return Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
    : Math.sign(num) * Math.abs(num);
}

export const data = {
  [Pricing.plans_all.freemium]: {
    range: {
      min: Pricing.plans_pricing.freemium.range.min,
      max: Pricing.plans_pricing.freemium.range.max,
      default: Pricing.plans_pricing.freemium.range.min,
      labels: [
        kFormatter(Pricing.plans_pricing.freemium.range.min),
        kFormatter(Pricing.plans_pricing.freemium.range.max),
      ],
    },
    prices: Pricing.plans_pricing.freemium.prices,
  },
  [Pricing.plans_all.basic]: {
    range: {
      min: Pricing.plans_pricing.basic.range.min,
      max: Pricing.plans_pricing.basic.range.max,
      default: Pricing.plans_pricing.basic.range.min,
      labels: [
        kFormatter(Pricing.plans_pricing.basic.range.min),
        kFormatter(Pricing.plans_pricing.basic.range.max),
      ],
    },
    prices: Pricing.plans_pricing.basic.prices,
  },
  [Pricing.plans_all.professional]: {
    range: {
      min: Pricing.plans_pricing.professional.range.min,
      max: Pricing.plans_pricing.professional.range.max,
      default: Pricing.plans_pricing.professional.range.max * 0.2,
      labels: [
        kFormatter(Pricing.plans_pricing.professional.range.min),
        kFormatter(Pricing.plans_pricing.professional.range.max),
      ],
    },
    prices: Pricing.plans_pricing.professional.prices,
  },
  [Pricing.plans_all.enterprise]: {
    range: {
      min: Pricing.plans_pricing.enterprise.range.min,
      max: Pricing.plans_pricing.enterprise.range.max,
      default: Pricing.plans_pricing.enterprise.range.max * 0.2,
      labels: [
        kFormatter(Pricing.plans_pricing.enterprise.range.min),
        kFormatter(Pricing.plans_pricing.enterprise.range.max),
      ],
    },
    prices: Pricing.plans_pricing.enterprise.prices,
  },
};

export const getStepForValue = (value) => {
  if (value >= 150000) {
    return 10000;
  }
  if (value >= 75000) {
    return 5000;
  }
  return 1000;
};

export const getPriceForValue = (unit, prices, currency, billingCycle) => {
  const currencyField = currency.toUpperCase();

  for (let i = 0; i < prices.length; i += 1) {
    const el = prices[i];
    const between = unit >= el.from && unit <= el.to;
    const above = !between && el.from && !el.to;

    if (between || above) {
      return Number((el.price[currencyField][billingCycle] * unit).toFixed(0));
    }
  }
  return 0;
};

export const getSavings = (unit, prices, currency, billingCycle) => {
  const currencyField = currency.toUpperCase();

  for (let i = 0; i < prices.length; i += 1) {
    const el = prices[i];
    const between = unit >= el.from && unit <= el.to;
    const above = !between && el.from && !el.to;

    if (between || above) {
      return parseInt(
        100 -
          (el.price[currencyField][billingCycle] * 100) /
            prices[0].price[currencyField][billingCycle],
        10,
      );
    }
  }
  return 0;
};
