import React from 'react';

import { Pricing } from './pricing_gen';
import { planPriority, Plans, showPlans } from './plans';
import { data as pricingData } from './index';

const numberWithSpaces = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const ALL_SYMBOL = '*';

// ClientSideFeatures
const ClientSideFeatures = {
  // billing & services
  Term: 'term',
  PaymentType: 'payment_type',
  EmailSupport: 'email_support',
  ChatSupport: 'chat_support',
  CustomPaperwork: 'custom_paperwork',
  Training: 'training',

  // privacy
  ContentExclude: 'content_exclude',
  GDPR: 'gdpr',
  CCPA: 'ccpa',

  // features usage
  SessionsLimit: 'sessions_limit',
  DataRetention: 'data_retention',

  // session replays
  SessionReplay: 'session_replay',
  PlaybackSpeed: 'playback_speed',
  Autoplay: 'autoplay',
  FormAnalytics: 'forms_analytics',
};

export const FeaturesBillingAndService = {
  Term: ClientSideFeatures.Term,
  PaymentType: ClientSideFeatures.PaymentType,
  EmailSupport: ClientSideFeatures.EmailSupport,
  ChatSupport: ClientSideFeatures.ChatSupport,
  CustomPaperwork: ClientSideFeatures.CustomPaperwork,
  Training: ClientSideFeatures.Training,
};

export const FeaturesPrivacy = {
  ContentExclude: ClientSideFeatures.ContentExclude,
  GDPR: ClientSideFeatures.GDPR,
  CCPA: ClientSideFeatures.CCPA,
};

export const FeaturesUsage = {
  SessionsLimit: ClientSideFeatures.SessionsLimit,
  DataRetention: ClientSideFeatures.DataRetention,
  Seats: Pricing.features.team_members,
  Websites: Pricing.features.websites,
};

export const FeaturesSessionReplays = {
  SessionReplay: ClientSideFeatures.SessionReplay,
  ClickMaps: Pricing.features.click_maps,
  Heatmaps: Pricing.features.heat_maps,
  EngagementScore: Pricing.features.engagement_score,
  PlaybackSpeed: ClientSideFeatures.PlaybackSpeed,
  SkipInactivity: Pricing.features.skip_inactivity,
  Autoplay: ClientSideFeatures.Autoplay,
  RecordingRules: Pricing.features.recording_rules,
  RageAndErrorClicks: Pricing.features.rage_and_error_clicks,
  Filters: Pricing.features.filters,
  FormAnalytics: ClientSideFeatures.FormAnalytics,
  SessionsSharing: Pricing.features.session_sharing,
  MinDuration: Pricing.features.min_duration,
  Tagging: Pricing.features.tagging,
  ResourceSynchronization: Pricing.features.resource_synchronization,
  DeveloperTools: Pricing.features.developer_tools,
};

export const FeaturesAnalytics = {
  Funnels: Pricing.features.funnels,
  Dashboard: Pricing.features.dashboards,
  UserJourney: Pricing.features.user_journey,
  Segments: Pricing.features.segments,
  SegmentNotifications: Pricing.features.segment_notifications,
  DataExport: Pricing.features.data_export,
};

export const FeaturesOther = {
  Integrations: Pricing.features.integrations,
  PremiumIntegrations: Pricing.features.premium_integrations,
  UserIdentification: Pricing.features.user_identification,
  CustomProperties: Pricing.features.custom_properties,
  MultiFactorAuthentication: Pricing.features.multi_factor_authentication,
  Notes: Pricing.features.notes,
  CustomEvents: Pricing.features.custom_events,
  RestAPI: Pricing.features.rest_api,
  AuditLogs: Pricing.features.audit_logs,
  SSO: Pricing.features.sso,
  Alerts: Pricing.features.alerts,
  Webhooks: Pricing.features.webhooks,
  CustomUserAccess: Pricing.features.custom_user_access,
  Organizations: Pricing.features.organizations,
};

export const Features = {
  BillingAndService: FeaturesBillingAndService,
  Privacy: FeaturesPrivacy,
  Usage: FeaturesUsage,
  SessionReplays: FeaturesSessionReplays,
  Analytics: FeaturesAnalytics,
  Other: FeaturesOther,
};

function clientSideFeatureConditions(plan) {
  const currentPlanPriority = planPriority(plan);

  let features = {};

  if (currentPlanPriority >= planPriority(Plans.Freemium)) {
    features = {
      ...features,
      [ClientSideFeatures.EmailSupport]: true,

      [ClientSideFeatures.ContentExclude]: true,
      [ClientSideFeatures.GDPR]: true,
      [ClientSideFeatures.CCPA]: true,

      [ClientSideFeatures.SessionsLimit]: numberWithSpaces(pricingData[Plans.Freemium].range.max),
      [ClientSideFeatures.DataRetention]: '1 month',

      [ClientSideFeatures.Term]: false,
      [ClientSideFeatures.PaymentType]: false,
      [ClientSideFeatures.ChatSupport]: false,
      [ClientSideFeatures.CustomPaperwork]: false,
      [ClientSideFeatures.Training]: false,

      [ClientSideFeatures.SessionReplay]: true,
      [ClientSideFeatures.PlaybackSpeed]: true,
      [ClientSideFeatures.Autoplay]: true,
      [ClientSideFeatures.FormAnalytics]: true,
    };
  }

  if (currentPlanPriority >= planPriority(Plans.Basic)) {
    features = {
      ...features,
      [ClientSideFeatures.Term]: 'monthly or annual',
      [ClientSideFeatures.PaymentType]: 'credit card',
      [ClientSideFeatures.ChatSupport]: true,
      [ClientSideFeatures.SessionsLimit]: `up to ${numberWithSpaces(
        pricingData[Plans.Basic].range.max,
      )}`,
    };
  }

  if (currentPlanPriority >= planPriority(Plans.Professional)) {
    features = {
      ...features,
      [ClientSideFeatures.DataRetention]: 'up to 12 months',
      [ClientSideFeatures.SessionsLimit]: `up to ${numberWithSpaces(
        pricingData[Plans.Professional].range.max,
      )}`,
    };
  }

  if (currentPlanPriority >= planPriority(Plans.Enterprise)) {
    features = {
      ...features,
      [ClientSideFeatures.Term]: 'annual',
      [ClientSideFeatures.PaymentType]: 'credit card or invoice',
      [ClientSideFeatures.CustomPaperwork]: true,
      [ClientSideFeatures.Training]: true,
      [ClientSideFeatures.SessionsLimit]: 'custom',
    };
  }

  return features;
}

const pricingFeaturesByPlan = showPlans.reduce(
  (acc, plan) => ({
    ...acc,
    [plan]: (Pricing.plans_pricing[plan] || {}).features,
  }),
  {},
);

function featureConditions(features) {
  return Object.values(features).reduce((acc, feature) => {
    function getValue(plan) {
      const pricingFeatures = pricingFeaturesByPlan[plan] || {};

      if (Object.prototype.hasOwnProperty.call(pricingFeatures, feature)) {
        const value = (pricingFeatures || {})[feature];
        if (value === ALL_SYMBOL) {
          return true;
        }

        if (feature === Features.Other.PremiumIntegrations) {
          return !!value;
        }

        return value;
      }

      return (clientSideFeatureConditions(plan) || {})[feature];
    }

    return {
      ...acc,
      [feature]: showPlans.reduce(
        (plans, plan) => ({
          ...plans,
          [plan]: getValue(plan),
        }),
        {},
      ),
    };
  }, {});
}

export const FeatureConditions = {
  BillingAndService: featureConditions(Features.BillingAndService),
  Privacy: featureConditions(Features.Privacy),
  Usage: featureConditions(Features.Usage, true),
  SessionReplays: featureConditions(Features.SessionReplays),
  Analytics: featureConditions(Features.Analytics),
  Other: featureConditions(Features.Other),
};

export const dataSet = [
  {
    name: 'Sessions monthly',
    tooltip: 'Number of sessions which can be recorded every month.',
  },
  {
    name: 'Data retention',
    tooltip: 'Choose for how long your data can be stored.',
  },
  {
    name: 'Websites',
    tooltip: 'Number of websites that can be tracked with one account.',
  },
  { name: 'Users', tooltip: 'Number of people that can use one account.' },
  {
    name: 'Session replays',
    tooltip: 'See mouse movements, clicks, scrolling and more.',
  },
  {
    name: 'Filters',
    tooltip: 'Use a wide range of filtering options to browse your recordings.',
  },
  {
    name: 'Segments',
    tooltip: 'Create custom segments to track different behavioral patterns.',
  },
  {
    name: 'Engagement score',
    tooltip: 'Find and watch the most interesting recordings.',
  },
  {
    name: 'Rage clicks & Error clicks',
    tooltip: 'Spot errors and points of frustration.',
  },
  {
    name: 'Inspect mode',
    tooltip: 'See how users interact with specific elements on your website.',
  },
  {
    name: 'Insights',
    tooltip: 'See top referrers, countries, UTMs, devices and more details.',
  },
  {
    name: 'Single-page application',
    tooltip: 'Works with React, Vue, Angular and other popular frameworks.',
  },
  {
    name: 'Resources sync',
    tooltip: 'CSS, fonts, images are saved to speed up session replays',
  },
  { name: 'Skip inactivity', tooltip: 'Skip idle moments in the recordings.' },
  {
    name: 'Speed up session replays',
    tooltip: 'Speed up recordings to watch them faster.',
  },
  {
    name: 'Tags',
    tooltip: 'Tag session recordings to find them easily later.',
  },
  {
    name: 'Custom properties',
    tooltip:
      'Extend sessions with custom data (e.g. subscription plan) and use it to filter your recordings.',
  },
  {
    name: 'Recording rules',
    tooltip: 'Choose to record sessions based on their URL, IP and other variables.',
  },
  {
    name: 'Always-on recordings',
    tooltip: 'Record all sessions, all the time.',
  },
  {
    name: 'User identification',
    tooltip: 'Identify your users by name or email.',
  },
  { name: 'Segment top lists', tooltip: 'See top results for each segment.' },

  {
    name: 'Premium support',
    tooltip: 'Quick responses and personalized approach via live chat and email.',
  },
  { name: 'Data integrations', tooltip: 'Segment, Google Analytics' },
  {
    name: 'Live chat integrations',
    tooltip: 'Intercom, LiveChat, Drift, Olark, Zendesk Chat, Crisp, HelpScout',
  },
  {
    name: 'Bug tracking integrations',
    tooltip: 'Sentry, Bugsnag, Rollbar, Airbrake, Errorception',
  },
  {
    name: 'Platforms installation',
    tooltip: 'Google Tag Manager, Wordpress, Wix, Shopify, BigCommerce.',
  },
];

export const MICRO = [
  '5 000',
  '1 month',
  1,
  3,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-cross" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-cross" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-cross" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
];
export const STARTER = [
  '10 000',
  '1 / 3 mo',
  3,
  10,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-cross" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-cross" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
];
export const STARTUP = [
  '25 000',
  '1 / 3 / 6 mo',
  5,
  20,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-cross" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
];
export const SCALE = [
  '50 000',
  '1 / 3 / 6 / 12 mo',
  10,
  50,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
  <i className="icon-check" />,
];
