import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Layout from 'layout';
import { DropdownToggle, DropdownMenu } from 'reactstrap';
import Features from 'components/pricing/features';
import FAQ from 'components/FAQ';
import PricingBox from 'components/pricing/pricingBox';
import StartUsing from 'components/ui/startUsing';
import Enterprise from 'components/pricing/enterprise';
import TrustedBy from 'components/ui/trustedBy';
import { featFree, featBasic, featProfessional, featEnterprise } from 'data/pricing/boxFeatures';
import { questions } from 'data/pricing/questions';
import { data } from 'data/pricing';

const Header = styled.section`
  padding: 180px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 0rem;
  position: relative;
  color: #000;
  ${media.tablet} {
    padding: 120px 0 0;
  }
  ${media.mobile} {
    padding: 6rem 0 0;
  }
  & h1 {
    margin: 0;
    margin-top: 0;
    & span {
      z-index: 100;
      &::after {
        z-index: -1;
      }
    }
    ${media.tablet} {
      font-size: 28px;
      line-height: 38px;
    }
  }
`;

const StyledSection = styled.section`
  padding: 48px 0 0;
  position: relative;
  & .container {
    padding: 0;
  }
  &::before {
    content: '';
    width: 100vw;
    height: 53.5%;
    background-color: #fdebbf;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    ${media.desktopWide} {
      height: 29.5%;
    }
    ${media.mobile} {
      height: 43%;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 16px 0 48px;
  font-weight: 500;
  color: #000;
  text-align: center;
  ${media.tablet} {
    margin: 24px 0 32px;
    font-size: 18px;
    width: 90%;
  }
`;

const Plans = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-content: center;
  & .pricing-box:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  & .pricing-box:last-of-type {
    border-radius: 0 4px 4px 0;
  }
  @media (max-width: 1300px) {
    grid-column-gap: 25px;
  }
  ${media.desktopWide} {
    grid-template-columns: repeat(2, 278px);
    grid-column-gap: 35px;
  }
  @media (max-width: 900px) {
    grid-column-gap: 30px;
    grid-template-columns: repeat(2, minmax(278px, min-content));
  }

  ${media.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const Switcher = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0 24px;
  align-self: flex-end;
  ${media.desktopWide} {
    align-self: center;
  }
  ${media.mobile} {
    margin: 40px 0 24px;
  }
  p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    margin-right: 16px;
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    li {
      color: #000;
      cursor: pointer;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;

      & strong {
        margin-left: 8px;
      }
      &:last-child {
        margin-left: 24px;
      }
      &.selected {
        color: #0446ca;
        position: relative;
        font-weight: 700;
        &::after {
          width: 100%;
          height: 2px;
          background-color: #0446ca;
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
        }
      }
      ${media.mobile} {
        font-size: 12px;
        &:last-child {
          margin-left: 16px;
        }
      }
    }
  }
`;

const PlansAndMethods = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile} {
    width: 100%;
    padding: 0 16px;
  }
`;

export const StyledDropdown = styled(DropdownToggle)`
  border-radius: 4px;
  border: 2px solid #000000;
  background: #fff;
  background-color: #fff;
  color: #000000;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  width: 85px;
  &:hover,
  &:active,
  &:focus {
    background-color: #fff !important;
    color: #000000 !important;
    box-shadow: none !important;
    border: 2px solid #000000 !important;
  }
  & img {
    width: 14px;
    height: 8px;
    transition: transform 0.2s ease;
    transform-origin: 50% 50%;
  }
  & p {
    margin: 0 16px 0 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: normal;
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  min-width: auto;
  transform: translate3d(0px, 34px, 0px) !important;
  border: 2px solid #000000 !important;
  margin: 0;
  border-radius: 4px;
  & .dropdown-item {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    &:focus,
    &:hover,
    &:active {
      background-color: #000;
      color: #fff;
    }
    &:first-child {
      border-bottom: 2px solid #000000 !important;
    }
  }
`;

const Pricing = () => {
  const [type, setType] = useState('monthly');
  const [currency, setCurrency] = useState('usd');

  useEffect(() => {
    fetch('https://freegeoip.live/json/')
      .then((res) => res.json())
      .then((res) => {
        if (res?.time_zone.includes('Europe')) {
          setCurrency('eur');
        }
      });
  }, []);

  return (
    <Layout
      metaTitle="Pricing | Top Session Replay Software"
      canonical="/pricing/"
      metaDescription="See LiveSession’s pricing options and find the best match for your session recording needs. Choose a plan or request a tailor-made session replay solution."
      hideBorder
    >
      <Header style={{ backgroundColor: '#FDEBBF' }}>
        <Container className="container">
          <h1 className="line">
            Find the right plan for your <span>business</span>
          </h1>
          <Subtitle>
            Get access to LiveSession analytics platform and see what users really do on your
            website. No credit card required.
          </Subtitle>
        </Container>
      </Header>
      <StyledSection>
        <div className="container">
          <PlansAndMethods>
            <Switcher>
              <ul>
                <li
                  className={type === 'monthly' ? 'monthly selected' : ''}
                  onClick={() => setType('monthly')}
                  onKeyPress={() => setType('monthly')}
                  role="presentation"
                >
                  Monthly prices
                </li>
                <li
                  className={[type === 'annual' ? 'annual selected' : '']}
                  onClick={() => setType('annual')}
                  onKeyPress={() => setType('annual')}
                  role="presentation"
                >
                  Annual prices <strong> -15%</strong>
                </li>
              </ul>

              <ul>
                <li
                  className={currency === 'usd' ? 'monthly selected' : ''}
                  onClick={() => setCurrency('usd')}
                  onKeyPress={() => setCurrency('usd')}
                  role="presentation"
                >
                  USD
                </li>
                <li
                  className={[currency === 'eur' ? 'annual selected' : '']}
                  onClick={() => setCurrency('eur')}
                  onKeyPress={() => setCurrency('eur')}
                  role="presentation"
                >
                  EUR
                </li>
              </ul>
            </Switcher>
            <Plans>
              <PricingBox
                free
                type={type}
                planName="Free"
                features={featFree}
                currency={currency}
                use="A simple way to get started with LiveSession"
              />
              <PricingBox
                data={data.basic}
                type={type}
                planName="Basic"
                currency={currency}
                features={featBasic}
                use="For small teams and ealry-stage startups"
              />
              <PricingBox
                data={data.professional}
                type={type}
                planName="Professional"
                features={featProfessional}
                currency={currency}
                mostPopular
                use="Fully featured solution for growing companies"
              />
              <PricingBox
                custom
                type={type}
                planName="Enterprise"
                currency={currency}
                use="Most powerful solution tailored to your scale"
                features={featEnterprise}
              />
              {/* <PricingBox planName="Large" pageviews="1 000 000" price="699€" /> */}
            </Plans>
          </PlansAndMethods>
        </div>
      </StyledSection>
      <div className="container">
        <Header style={{ paddingTop: 60 }}>
          <Features />
        </Header>
        <Enterprise />
        <FAQ questions={questions} style={{ paddingTop: '7rem' }} />
      </div>
      <TrustedBy
        style={{ paddingBottom: 0 }}
        customText={
          <>
            <b>1000+</b> teams across <b>66</b> countries. <b>5M+</b> unique users analyzed every
            month.
          </>
        }
      />
      <StartUsing />
    </Layout>
  );
};

export default Pricing;
