export const questions = [
  {
    question: 'What is the commitment? Do we need to sign a contract?',
    answer:
      "You need to accept a SaaS subscription agreement. There's no need to sign a hard copy. You can change your plan whenever you want. If you choose to pay on a monthly basis, you can cancel your subscription at any time.",
  },
  {
    question: 'What is a session?',
    answer:
      'A session is defined as all the interactions one user takes during one visit. Session ends after the user is not active for 30 minutes. Everything the user does on your website before they leave (e.g. browses pages, clicks, scrolls, writes on keyboard) adds up to one session.',
  },
  // {
  //   id: 3,
  //   question: "Can I track multiple websites with one subscription?",
  //   answer:
  //     "Yes! You can choose one subscription plan for all websites. What's more, you can connect an unlimited number of domains to your account."
  // },
  {
    question: 'What is a session replay?',
    answer:
      'A session replay is a playback of all actions taken by the user during one visit. You can see mouse movements, clicks, scrolls and all kinds of changes in the interface.',
  },
  {
    question: 'Can I cancel my subscription anytime?',
    answer: 'Yes, if you choose to pay on a monthly basis.',
  },
  {
    question: 'What happens to my recordings after the retention period?',
    answer:
      'Depending on your plan, the recordings are permanently deleted after 30, 90, 180 or 365 days.',
  },
];
