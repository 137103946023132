import React from 'react';

export const featFree = [
  {
    name: 'Session replays',
    tooltip: 'See mouse movements, clicks, scrolling and more.',
  },
  {
    name: 'Segments',
    tooltip: 'Create custom segments to track different behavioral patterns.',
  },
  {
    name: 'Filters',
    tooltip: 'Use a wide range of filtering options to browse your recordings.',
  },
  {
    name: 'Engagement score',
    tooltip: 'Find and watch the most interesting recordings.',
  },
  {
    name: 'Clickmaps & Heatmaps',
    tooltip:
      'Visualize most engagement places on your website and better analyze users interactions.',
  },
];
export const featBasic = [
  {
    name: (
      <>
        Everything in <strong>Free</strong> plan
      </>
    ),
  },
  {
    name: 'User identification',
    tooltip: 'Identify your users by name or email.',
  },
  {
    name: 'Segment notifications',
    tooltip: 'Daily or weekly email notifications about segment changes.',
  },
  {
    name: 'Resource backuping',
    tooltip: 'Daily or weekly email notifications about segment changes.',
  },
  {
    name: 'Minumum duration on page',
    tooltip: 'Daily or weekly email notifications about segment changes.',
  },
];
export const featProfessional = [
  {
    name: (
      <>
        Everything in <strong>Basic</strong> plan
      </>
    ),
  },
  {
    name: 'DevTools',
    tooltip:
      'Extend sessions with custom data (e.g. subscription plan) and use it to filter your recordings.',
  },
  {
    name: 'REST API access',
    tooltip:
      'Extend sessions with custom data (e.g. subscription plan) and use it to filter your recordings.',
  },
  {
    name: 'Custom events',
    tooltip:
      'Extend sessions with custom data (e.g. subscription plan) and use it to filter your recordings.',
  },
  {
    name: 'Premium integrations (Zendesk, Intercom, LiveChat)',
    tooltip: 'See how users interact with specific elements on your website.',
  },
];
export const featEnterprise = [
  {
    name: (
      <>
        Everything in <strong>Professional</strong> plan
      </>
    ),
  },
  {
    name: 'SSO/SAML',
    tooltip: 'Quick responses and personalized approach via live chat and email.',
  },
  {
    name: 'Audit logs',
    tooltip: 'Quick responses and personalized approach via live chat and email.',
  },
  {
    name: 'Priority support',
    tooltip: 'Quick responses and personalized approach via live chat and email.',
  },
  {
    name: 'Custom invoicing',
    tooltip: 'Quick responses and personalized approach via live chat and email.',
  },
];
