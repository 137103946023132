import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Tippy from '@tippyjs/react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const StyledList = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  &.compare {
    li {
      width: 100%;
    }
  }
  li {
    padding: 20px 15px;
    width: 95%;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 800;
    position: relative;
    border-bottom: 1px solid #e4e5e8;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: 500;
      display: inline-block;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
      line-height: 1.8;
    }
    p {
      font-weight: 500;
      margin: 0;
    }
    i {
      font-size: 20px;
      position: relative;
      &.planned {
        &::after {
          content: 'PLANNED';
          font-style: normal;
          color: #000;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(calc(-100% - 14px), -50%);
          background-color: #ffe478;
          width: 67px;
          height: 25px;
          border-radius: 4px;
          font-size: 10px;
          font-weight: 700;
          line-height: 28px;
          text-align: center;
        }
      }
    }
    .icon-cross {
      color: #f50057;
    }
  }
  ${media.tablet} {
    max-width: 576px;
    margin: 0 auto;
  }
`;

const PaginationWrapper = styled.div`
  width: 100vw;
  ${media.tablet} {
    max-width: 576px;
    margin: 0 auto;
  }
  &.compare {
    transform: translateX(-15px);
    .pagination {
      padding: 0 15px;
    }
  }
  .pagination {
    border-bottom: 1px solid #e4e5e8;
    border-radius: 0;
    justify-content: space-evenly;
    padding: 0;
    margin-bottom: 0;
  }
  .page-item {
    border-bottom: 2px solid #fff;
    width: 100%;
    text-align: center;
  }
  .page-link {
    font-size: 16px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    color: #000;
    transition: 0.2s;
    opacity: 0.6;
    outline: 0 !important;
    padding: 0.5rem 0.65rem 15px;
  }
  .page-item.active {
    border-bottom: 2px solid #0a4ed6;
    transition: 0.2s;
    .page-link {
      background-color: transparent;
      color: #000;
      outline: 0 !important;
      transition: 0.2s;
      font-weight: 800;
      opacity: 1;
      border-color: transparent;
      &:focus {
        box-shadow: none;
      }
    }
  }
`;
const List = styled.div`
  display: none;
  margin-top: 64px;
  li:last-child {
    border-bottom: none;
  }
  ${media.tablet} {
    display: block;
  }
`;

const TableMobile = ({ pages, data, compare }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  return (
    <List>
      <PaginationWrapper className={['pagination-wrapper', compare && 'compare'].join(' ')}>
        <Pagination aria-label="Page navigation">
          {pages.map((page) => (
            <PaginationItem active={page.key === currentPage} key={page.key}>
              <PaginationLink onClick={(e) => handleClick(e, page.key)} href="#">
                {page.name}
              </PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>
      </PaginationWrapper>
      <StyledList className={[compare && 'compare']}>
        {data.map((item, i) => (
          <li key={item.name}>
            {item.tooltip ? (
              <Tippy content={item.tooltip} placement="bottom-start">
                <span>{item.name}</span>
              </Tippy>
            ) : (
              <p>{item.name}</p>
            )}
            {pages[currentPage].base[i]}
          </li>
        ))}
      </StyledList>
    </List>
  );
};

export default TableMobile;

TableMobile.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      base: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
      key: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
  compare: PropTypes.bool,
};

TableMobile.defaultProps = {
  compare: false,
};
